import fetchAPI from "./api.mjs";
import generateButton from "./generateButton.mjs";

export default async function agendaimmo_negociateur(agence_id, negoEmailOrPhone, options, portal_options = {}) {
  const { url } = await fetchAPI('/agents/check/' + agence_id + '/' + encodeURI(negoEmailOrPhone), "GET")
  /* s'il s'agit d'un bouton Portail on inclue l'url calculé dans les options et on redirige vers la page de dispatch spécifique aux portails */
  if (portal_options.hasOwnProperty("portal_slug")) {
    portal_options.url = url
    url = URL_API + '/portalApi/redir/' + btoa(JSON.stringify(portal_options))
  }
  generateButton(url, { text: "prendre rendez-vous", ...options })
}