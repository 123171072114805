import generateButton from "./generateButton.mjs";
import { URL_API } from "./utils/constants.mjs";

/**
 * 
 * @param {number} nom_agence 
 * @param {string} ref_interne 
 * @param {Object} options 
 * @description Ajoute le bouton de prise de rdv
 */
export default async function agendaimmo_agence(nom_agence, options, portal_options = {}) {

  var url = URL_API + '/rdvs/rdvAgence/' + nom_agence
  /* s'il s'agit d'un bouton Portail on inclue l'url calculé dans les options et on redirige vers la page de dispatch spécifique aux portails */
  if (portal_options.hasOwnProperty("portal_slug")) {
    portal_options.url = url
    url = URL_API + '/portalApi/redir/' + btoa(JSON.stringify(portal_options))
  } 


  generateButton(url, {text:"rendez-vous agence",...options})

}