import generateButton from "./generateButton.mjs";

/**
 * 
 * @param {string} url 
 * @param {Object} options 
 * @description Ajoute le bouton de prise de rdv
 */
export default function agendaimmo_estimation(url, options = {}) {
  if(!url)return
  if(!options.hasOwnProperty("text")) options.text = "Rendez-vous Estimation"
  generateButton(url, options)
}