import jsStyleToString from "../utils/jsStyleToString.mjs"

export const styles_container = jsStyleToString({
  textDecoration: 'none',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  padding: '10px 20px',
  bottom: '20px',
  gap: '10px',
  right: '30px',
  'z-index': '999',
  color: 'var(--color-ai-text)',
  background: 'var(--color-ai-bg)'
})

export const styles_container_inline = jsStyleToString({
  textDecoration: 'none',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  padding: '10px 20px',
  bottom: '0',
  gap: '10px',
  right: '0',
  'z-index': '999',
  color: 'var(--color-ai-text)',
  background: 'var(--color-ai-bg)'
})