import { URL_API } from "./utils/constants.mjs";

export default async function fetchAPI(url, method, body) {

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  }

  const res = await fetch(URL_API + url, {
    method,
    headers,
    body,
  })

  const json = await res.json()

  if (json.errors) {
    console.log(json.errors)
    throw new Error("Agenda immo re répond pas")
  }

  return json
}