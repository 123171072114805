import agendaimmo from  './agendaimmo.mjs'
import agendaimmo_estimation from './agendaimmo_estimation.mjs';
import agendaimmo_negociateur from './agendaimmo_negociateur.mjs';
import agendaimmo_agence from './agendaimmo_agence.mjs';

//functions for insert on script
window.agendaimmo = agendaimmo;
window.agendaimmo_negociateur = agendaimmo_negociateur
window.agendaimmo_estimation = agendaimmo_estimation
window.agendaimmo_agence = agendaimmo_agence


