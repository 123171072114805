import { default_options } from "./defaultOptions.mjs";
import { images } from "./images.mjs";
import addStyle from "./utils/addStyle.mjs";

export default function generateButton(url, options) {
  if (!checkUrl(url)) return;
  const
    { theme_logo, text, id } = { ...default_options, ...options },
    img_src = images[theme_logo]
  // create DOM elements  
  const
    container = document.createElement('a'),
    textContainer = document.createElement('div'),
    img = document.createElement('img')

  // prepare elements
  addStyle(container, id ? 'styles_container_inline' : 'styles_container', options, theme_logo)
  addStyle(img, 'styles_icon', options, theme_logo)
  addStyle(textContainer, 'styles_button', options, theme_logo)

  container.setAttribute('href', url)
  container.setAttribute('target', '_blank')
  img.setAttribute('src', img_src)
  textContainer.innerText = text

  //attach to DOM
  container.appendChild(img)
  container.appendChild(textContainer)
  if (id) {
    const pageElement = document.getElementById(id)
    pageElement.appendChild(container)
  } else {
    document.body.appendChild(container)
  }
}

function checkUrl(url) {
  let givenURL;
  try {
    givenURL = new URL(url);
  } catch (error) {
    console.log("Invalid URL", error);
    return false;
  }
  return true;
}