import { styles_button } from "./styles/styles_button.mjs";
import { styles_container, styles_container_inline } from "./styles/styles_container.mjs";
import { styles_icon } from "./styles/styles_icon.mjs";

export const default_options = {
  styles_container,
  styles_container_inline,
  styles_button,
  styles_icon,
  text: "rendez-vous visite",
  theme_logo: "light",
  bgcolor: "#555",
  color: "#fff"
};