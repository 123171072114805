import fetchAPI from "./api.mjs";
import generateButton from "./generateButton.mjs";
import { URL_API } from "./utils/constants.mjs";

/**
 * 
 * @param {number} agence_id 
 * @param {string} ref_interne 
 * @param {Object} options 
 * @param {Object} portal_options {redirect_url, portal_slug}
 * @description Ajoute le bouton de prise de rdv
 */
export default async function agendaimmo(agence_id, ref_interne, options, portal_options = {}) {
  const { bien_id } = await fetchAPI('/biens/check/' + agence_id, "POST", 'u=' + ref_interne)
  if (!bien_id) return

  var url = URL_API + '/rdvs/rdv/' + bien_id
  /* s'il s'agit d'un bouton Portail on inclue l'url calculé dans les options et on redirige vers la page de dispatch spécifique aux portails */
  if (portal_options.hasOwnProperty("portal_slug")) {
    portal_options.url = url
    url = URL_API + '/portalApi/redir/' + btoa(JSON.stringify(portal_options))
  }

  generateButton(url, options)

}