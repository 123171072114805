import { default_options } from "../defaultOptions.mjs";
import camelCaseToKebabCase from "./camelCaseToKebabCase.mjs";
import jsStyleToString from "./jsStyleToString.mjs";

export default function addStyle(element,key,styles = {},theme){

  const transformStyleValue = (value) => {
    if(typeof value === 'undefined') return []
    if(typeof value === 'object') return jsStyleToString(value).split(';')
    return value?.split(';')
  }

  const 
    color = theme === 'light' ? 'white': '#eef0ef',
    bg = theme === 'light' ?  '#0e9b25':'#2a6332', 
    defaultStyle = default_options?.[key].split(';'),
    newStyle = transformStyleValue(styles?.[key.match(/inline/) ? key.replace('_inline',''):key])

    
  //take all keys, add default if not exist in user style
  const joinStyles = [...defaultStyle,...newStyle].reduce((prev,current)=>{
    if(!current)return prev

    const 
    currentKey = current.split(':').at(0),
      currentKeyKebabCase = camelCaseToKebabCase(current.split(':').at(0))
     
      //test CSS key, not value
      regex = new RegExp(currentKey),
      overwriteStyle = newStyle.find(element => element.match(regex))
      const 
        line = overwriteStyle || current,
        newLine = line.replace(currentKey,currentKeyKebabCase),
        isExist = prev.find(el=>el === newLine)
    if(!isExist)prev.push(line.replace(currentKey,currentKeyKebabCase))
    return prev
  },[])

  const colors = `--color-ai-text:${color};--color-ai-bg:${bg};`
  element.setAttribute('style',colors + joinStyles.join(';'))
  return element
}